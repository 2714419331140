import React from "react";
import "./Courses.css";
import coachingImage from "../../assets/images/coaching.webp";

const Courses = () => {
  return (
    <div className="courses-container">
      <div className="courses-content">
        <section className="youtube-section">
          <div className="content">
            <h2>유튜브 멤버십</h2>
            <p>
              유튜브 채널 멤버로 가입하시면 다음과 같은 혜택을 받으실 수
              있습니다:
            </p>
            <ul>
              <li>가입자 전용 Discord 채널 초대</li>
              <li>
                가입자 전용 채널에서 질문할 수 있고 우선적으로 답변 받을 수 있음
              </li>
              <li>쿠폰 할인 혜택</li>
              <li>Discord에서 다른 개발자들과의 교류할 수 있음</li>
            </ul>
            <a
              href="https://www.youtube.com/@survivalcoding"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button"
            >
              유튜브 채널 가입하기
            </a>
          </div>
          <div className="emoji-container">
            <span role="img" aria-label="유튜브" className="large-emoji">
              🎥
            </span>
          </div>
        </section>

        <section className="consulting-section">
          <div className="consulting-header">
            <h2>교육 및 컨설팅</h2>
            <p className="consulting-description">
              개인 또는 그룹 교육을 통해 개발자로써의 성장을 지원해 드립니다. 각
              분야별 전문 교육으로 실무에 필요한 역량을 체계적으로 키워보세요.
            </p>
          </div>

          <div className="consulting-cards">
            <div className="consulting-card">
              <div className="card-icon">
                <span role="img" aria-label="안드로이드">
                  📱
                </span>
              </div>
              <h3>Android 개발자 교육</h3>
              <div className="content">
                <ul>
                  <li>Kotlin & Jetpack Compose</li>
                  <li>MVVM 패턴 기반 안드로이드 앱 개발</li>
                  <li>Coroutine & Flow</li>
                  <li>Hilt, Coin을 활용한 의존성 주입</li>
                  <li>안드로이드 앱 디버깅 및 테스트</li>
                </ul>
              </div>
            </div>

            <div className="consulting-card">
              <div className="card-icon">
                <span role="img" aria-label="플러터">
                  🦋
                </span>
              </div>
              <h3>Flutter 개발자 교육</h3>
              <div className="content">
                <ul>
                  <li>Flutter & Dart 기반</li>
                  <li>Android, iOS, Web, Desktop, Linux, MacOS 앱 개발</li>
                  <li>네이티브 연동 방법</li>
                  <li>상태 관리</li>
                  <li>Unit Test, Integration Test</li>
                </ul>
              </div>
            </div>

            <div className="consulting-card">
              <div className="card-icon">
                <span role="img" aria-label="자동화">
                  🤖
                </span>
              </div>
              <h3>업무 자동화 컨설팅</h3>
              <div className="content">
                <ul>
                  <li>n8n, Lindy 등 업무 자동화 도구 기반</li>
                  <li>반복 업무 자동화 및 생산성 향상</li>
                  <li>워크플로우 설계 및 구현</li>
                  <li>워크플로우 테스트 및 디버깅</li>
                  <li>워크플로우 모니터링 및 로깅</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="image-container">
            <img
              src={coachingImage}
              alt="원격 코칭"
              className="coaching-image"
            />
          </div>

          {/* 기타 강의 가능 주제 섹션 */}
          <div className="consulting-header" style={{ marginTop: "60px" }}>
            <h2>기타 강의 가능 주제</h2>
            <p className="consulting-description">
              다음 주제에 대한 맞춤형 교육도 제공하고 있습니다. 필요한 주제가
              있으시면 문의해 주세요.
            </p>
          </div>

          <div className="consulting-cards">
            <div className="consulting-card">
              <div className="card-icon">
                <span role="img" aria-label="자바">
                  ☕
                </span>
              </div>
              <h3>Java</h3>
              <div className="content">
                <ul>
                  <li>객체지향 프로그래밍 기초 및 심화</li>
                  <li>Java 8+ 주요 기능 활용</li>
                  <li>디자인 패턴 적용</li>
                  <li>효율적인 Java 코드 작성법</li>
                  <li>Unit Test</li>
                </ul>
              </div>
            </div>

            <div className="consulting-card">
              <div className="card-icon">
                <span role="img" aria-label="웹">
                  🌐
                </span>
              </div>
              <h3>Web 기초</h3>
              <div className="content">
                <ul>
                  <li>HTML5 구조 및 시맨틱 태그</li>
                  <li>CSS3 레이아웃 및 반응형 디자인</li>
                  <li>JavaScript 기본 문법 및 DOM 조작</li>
                  <li>웹 API 활용</li>
                  <li>실전 웹 프로젝트 구현</li>
                </ul>
              </div>
            </div>

            <div className="consulting-card">
              <div className="card-icon">
                <span role="img" aria-label="깃">
                  🔄
                </span>
              </div>
              <h3>형상관리(Git)</h3>
              <div className="content">
                <ul>
                  <li>Git 기본 개념 및 명령어</li>
                  <li>브랜치 전략 및 워크플로우</li>
                  <li>협업을 위한 Pull Request</li>
                  <li>충돌 해결 및 리베이스</li>
                  <li>GitHub/GitLab 활용법</li>
                </ul>
              </div>
            </div>
          </div>

          {/* 문의 안내 섹션 */}
          <div
            className="contact-info"
            style={{ marginTop: "60px", textAlign: "center" }}
          >
            <h2>문의 안내</h2>
            <p className="consulting-description">
              맞춤형 교육 프로그램 구성, 기업 교육, 그룹 강의 등 자세한 사항은
              이메일로 문의해 주세요.
            </p>
            <a
              href="mailto:info@survivalcoding.com"
              className="email-link"
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
                color: "#007bff",
                textDecoration: "none",
                fontWeight: "600",
                fontSize: "1.1rem",
              }}
            >
              <span
                role="img"
                aria-label="이메일"
                style={{ marginRight: "10px", fontSize: "1.5rem" }}
              >
                📧
              </span>
              info@survivalcoding.com
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Courses;
